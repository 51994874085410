.user-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  background-color: lightblue!important;

  &__total {
    padding-right: 10px;
    text-align: right;
  }
}

.bottom-border {
  border-bottom: 2px solid;
}

.top-border {
  border-top: 2px solid;
}