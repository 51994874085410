.worklog {
  background-color: lightgoldenrodyellow;
  & td {
    overflow-x: auto;
  }
  & td:first-child {
    max-width: 120px;
  }
  & td:nth-child(2) {
    @media screen and (max-width:1500px) {
      max-width: 600px;
    }
    @media screen and (max-width:1300px) {
      max-width: 500px;
    }
  }
}

.easy-edit-component-wrapper {
  width: 100%;
}

.timespent-box {
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
}

.timespent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}

.comment {
  min-height: 18px;
  width: 100%;
  display: block;
}

.comment-cell {
  cursor: pointer;
}

.delete-btn-box {
  width: 100px;
  margin-left: 10px;
}
