.login {
  display: flex;
  height: 100vh;

  &__button {
    font-size: 16px !important;
    width: 200px;
    margin-bottom: 15px;
  }

  .form {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__error {
      font-size: 10px;
      margin: 10px 0px;
      color: red;
    }  

    &__container {
      display: flex;
      flex-direction: column;
    }
  }
}
